<template>
  <div class="card" @mouseover="hoverCard(true)" @mouseleave="hoverCard(false)">
    <div class="card-inner" ref="cardInner">
      <div class="card-front">
        <div class="card-icon-border">
          <img src="../../public/img/logocv.png" alt="video content">
        </div>
        <h4>Vachu</h4>
      </div>
      <div class="card-back">
        <div class="face back">
          <div class="m-2 p-5">
            <i>
              <q class="quote">
                I love earning platinum trophies in video games. When I'm not gaming, I enjoy listening to music, revisiting classic vinyl records, or indulging in a good book or movie. As a passionate Belgrano fan, I follow their games closely and support them through thick and thin.              </q>
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardImg',
  methods: {
    hoverCard(isHovered) {
      if (isHovered) {
        this.$refs.cardInner.style.transform = 'rotateY(180deg)';
      } else {
        this.$refs.cardInner.style.transform = 'rotateY(0deg)';
      }
    }
  },
  mounted() {
    // Inicializar rotación automática y volver a la posición original
    setTimeout(() => {
      this.$refs.cardInner.style.transform = 'rotateY(180deg)';
    }, 500);

    setTimeout(() => {
      this.$refs.cardInner.style.transform = 'rotateY(0deg)';
    }, 1500);
  }
}
</script>

<style scoped>
h3 {
  font-size: 2em;
  font-weight: 900;
  margin: 0 0 0.25em 0;
  position: relative;
  display: inline-block;
}

h4 {
  color: #111111;
  margin-left: 1.5rem;
  font-size: 2em;
  font-weight: 300;
  margin-top: 0;
}

.card {
  cursor: pointer;
  color: #fff;
  height: 32em;
  width: 23em;
  margin: 2em;
  background-color: #272828;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: 0.75em 0.75em 0 0 #fdbe15;
  border: 0.25em solid #272828;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  transition: ease all 0.15s;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.card-front {
  transform: rotateY(0deg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(../../public/img/peperina.png);
}

.card-back {
  transform: rotateY(180deg);
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

h4 {
  font-size: 1.25em;
  font-style: italic;
  font-weight: 800;
  background-color: #fdbe15;
  padding: 0.5em 1em;
  position: absolute;
  margin: 0;
  left: -1em;
  top: 1em;
}

.card-icon-border {
  position: absolute;
  top: 1em;
  right: 1.25em;
  height: 2.5em;
  width: 2.5em;
  border: solid 1px #000000;
  background-color: rgba(21, 22, 25, 0.8);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease all 0.15s;
}

img {
  height: 2em;
  width: 2em;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .card {
    height: 20em;
    width: 15em;
  }

  h3,
  h4 {
    font-size: 1em;
  }

  .quote {
    font-size: 10px;
  }
}

@media screen and (max-width: 1050px) {
  .card {
    height: 20em;
    width: 15em;
  }

  h3,
  h4 {
    font-size: 1em;
  }

  .quote {
    font-size: 10px;
  }
}

@media screen and (max-width: 425px) {
  .card {
    height: 20em;
    width: 15em;
  }

  h3,
  h4 {
    font-size: 1em;
  }
}

@media screen and (max-width: 375px) {
  .card {
    height: 20em;
    width: 15em;
  }

  h3,
  h4 {
    font-size: 1em;
  }
}

@media screen and (max-width: 320px) {
  .card {
    height: 20em;
    width: 15em;
  }

  h3,
  h4 {
    font-size: 1em;
  }
}

@media screen and (max-width: 1600px) {
  .card {
    height: 23em;
    width: 22em;
  }

  h3,
  h4 {
    font-size: 1em;
  }
}

@media screen and (max-width: 1100px) {
  .card {
    height: 20em;
    width: 15em;
  }

  h3,
  h4 {
    font-size: 1em;
  }
}

@media screen and (min-width: 1821px) and (max-width: 2560px) and (min-resolution: 192dpi) {
  .card {
    height: 42em;
    width: 40em;
  }

  .quote {
    font-size: 30px;
  }
}
</style>
